import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux'
import { persistor, store} from './redux/store'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
		<PersistGate loading={null} persistor={persistor} >
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</PersistGate>
    </Provider>
  </React.StrictMode>
);

