export function Banner(props:any){
	return (
		<section>
		<div className="top-category-ads">
			<div className="container">
			<div className="ads-container">
				<div className="full item">
				<a href="/" target="_top">
					<img src="/images/banners/xiaomi-13-series-01.jpg" alt="bannner" className="img-responsive img-border-radius" /></a>
				</div>
			</div>
			</div>
		</div>
	</section>
	)
}